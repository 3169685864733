import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "../components/Container"
import {SectionTitle} from "../components/SectionTitle/styles"
import Transparency from "../images/transparency.svg"
import Competitiveness from "../images/competitiveness.svg"
import Minimalism from "../images/minimalism.svg"
import CirclesFour from "../images/circlesfour.svg"
import MonitorCart from "../images/monitorcart.svg"
import {ButtonGf, ButtonLink, PopupText} from "../components/Button/styles";
import { colors } from "../const/colors"
import {IoCheckmark} from "react-icons/io5"
import {InfoBox} from "../components/Footer/styles";
import {AddPackagesSection, BannerSection, BenefitsSection} from "../components/WebsitePage/styles";
import CircleTwo from "../images/greyCirclesLeft.svg";
import CircleThree from "../images/grayCirclesRight.svg";
import CircleImagine from "../images/ImagineCircleRight.svg"
import { BannerStyles} from "../const/inline_styles";
import AnimatedInViewWrapper from "../components/AnimatedInViewWrapper";
import {ImagineItSection, TargetSection} from "../components/OnlineShopPage/styles";
import AddToWebsite from "../components/AddToWebsite";
import localData from "../localization/online-shop.json";

// const flags = {
//     "en": localData.locales[0].value,
//     "es": localData.locales[1].value
// }

const flags = [
    {
        name: "en",
        uri: localData.locales[0].value
    },
    {
        name: "es",
        uri : localData.locales[1].value
    }
]

const GfOnlineShop = ({data, location, pageContext}) => {
    const lang = pageContext.lang
    const meta = data?.template?.locales.find(l => l.iso === lang)
    const metaStatic = localData.locales.find(l => l.iso === lang)
    return <Layout location={location} lang={lang} flags={flags}>
        <SEO
            title={ meta?.metaTitle || metaStatic.metaTitle}
            description={ meta?.metaDescription || metaStatic.metaDescription}
            keywords={ meta?.metaKeywords || metaStatic.metaKeywords}
        />
        <BannerSection>
            <Container>
                <h2 dangerouslySetInnerHTML={{__html: localData.pageTitle[lang]}} style={BannerStyles.h2} />
                <SectionTitle>
                    {localData.sections.hero.title[lang]}
                </SectionTitle>
                <p style={{maxWidth:"900px"}}>{localData.sections.hero.description[lang]}</p>
                <div className="info-box-list">
                    <InfoBox step={1} icon={<img src={Transparency} alt="flexible" />} text={localData.sections.hero.group.first[lang]} color={colors.grayColor} />
                    <InfoBox step={2} size="27px" icon={<img src={Competitiveness} alt="customized" />} text={localData.sections.hero.group.second[lang]} color={colors.grayColor} />
                    <InfoBox step={3} icon={<img src={Minimalism} alt="optimized" />} text={localData.sections.hero.group.third[lang]} color={colors.grayColor} />
                </div>
                <div className="free-consultation">
                    <PopupText text={localData.sections.hero.button.label[lang]} url="https://calendly.com/tina-greenflamingo/30min" />
                </div>
            </Container>
            <div className="bg-image">
                <img src={CirclesFour} alt="circles"/>
            </div>
        </BannerSection>
        <BenefitsSection>
            <Container>
                <SectionTitle align="center">{localData.sections.benefits.title[lang]}</SectionTitle>
                <div className="content">
                    <p style={{textAlign:"center"}}>{localData.sections.benefits.description[lang]}</p>

                    <ul>
                        <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{__html: localData.sections.benefits.group.first[lang]}} /></li>
                        <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{__html: localData.sections.benefits.group.second[lang]}} /></li>
                        <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{__html: localData.sections.benefits.group.third[lang]}} /></li>
                        <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{__html: localData.sections.benefits.group.fourth[lang]}} /></li>
                        <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{__html: localData.sections.benefits.group.fifth[lang]}} /></li>
                        <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{__html: localData.sections.benefits.group.sixth[lang]}} /></li>
                    </ul>
                    <div className="get-in-touch">
                        <h4>{localData.sections.benefits.sectionSubTitle[lang]}</h4>
                        <span>{localData.sections.benefits.sectionSubDescription[lang]}</span>
                        {/*<ButtonGf>Start</ButtonGf>*/}
                        <div className="free-consultation free-consultation--flex">
                            <PopupText url="https://calendly.com/tina-greenflamingo/30min" text={localData.sections.benefits.button.label[lang]} />
                        </div>
                    </div>
                </div>
            </Container>
            <img className="left" src={CircleTwo} alt="circles two"/>
            <img className="right" src={CircleThree} alt="circles three"/>
        </BenefitsSection>
        <TargetSection>
            <Container>
                <SectionTitle align="center">{localData.sections.target.title[lang]}</SectionTitle>
                <div className="content">
                    <p>{localData.sections.target.description[lang]}</p>
                    <div className="main-content">
                        <div className="img-block">
                            <AnimatedInViewWrapper initial="hidden"

                                                   variants={{
                                                       visible: {
                                                           y:0,
                                                           opacity:1,
                                                           transition: {
                                                               delay:.3,
                                                               duration:.6
                                                           }
                                                       },
                                                       hidden: {
                                                           y:"50px",
                                                           opacity:0,
                                                           transition: {
                                                               duration:.6
                                                           }
                                                       }
                                                   }}>
                                <img src={MonitorCart} alt="monitor"/>
                            </AnimatedInViewWrapper>
                        </div>
                        <ul>
                            <li><IoCheckmark color={colors.greenColor} /><span>{localData.sections.target.group.first[lang]}</span></li>
                            <li><IoCheckmark color={colors.greenColor} /><span>{localData.sections.target.group.second[lang]}</span></li>
                            <li><IoCheckmark color={colors.greenColor} /><span>{localData.sections.target.group.third[lang]}</span></li>
                            <li><IoCheckmark color={colors.greenColor} /><span>{localData.sections.target.group.fourth[lang]}</span></li>
                            <li><IoCheckmark color={colors.greenColor} /><span>{localData.sections.target.group.fifth[lang]}</span></li>
                            <li><IoCheckmark color={colors.greenColor} /><span>{localData.sections.target.group.sixth[lang]}</span></li>
                        </ul>
                    </div>
                    <div className="button-wrapper">
                        <ButtonLink to={localData.sections.target.button.uri[lang]} color="white" background={colors.greenColor} bgHoverColor={colors.grayColor}>{localData.sections.target.button.label[lang]}</ButtonLink>
                    </div>
                </div>
            </Container>
        </TargetSection>
        <ImagineItSection>
            <Container>
                <SectionTitle align="center">{localData.sections.custom.title[lang]}</SectionTitle>
                <div className="content">
                    <p>{localData.sections.custom.description[lang]}</p>
                    <div style={{margin:"50px auto"}} className="button-wrapper">
                        {/*<ButtonGf>Explore</ButtonGf>*/}
                        <div className="free-consultation" style={{display:"flex", justifyContent:"center"}}>
                            <PopupText text={localData.sections.custom.button.label[lang]} url="https://calendly.com/tina-greenflamingo/30min" />
                        </div>
                    </div>
                    <h2 style={{
                        color: colors.greenColor,
                        fontWeight: "bold",
                        margin:"50px auto",
                        textTransform: "uppercase",
                        fontSize: "clamp(1.5rem,6vw,2.6rem)"
                    }}>{localData.sections.custom.sectionSubTitle[lang]}</h2>
                </div>
            </Container>
            <img className="left" src={CircleTwo} alt="circles two"/>
            <img className="right" src={CircleImagine} alt="circles three"/>
        </ImagineItSection>
        <AddPackagesSection>
            <Container>
                <SectionTitle align="center">{localData.sections.addto.title[lang]}</SectionTitle>
                <p style={{textAlign: "center"}}>{localData.sections.addto.description[lang]}</p>
                <AddToWebsite lang={lang} data={localData.sections.addto} subject={localData.sections.addto.subject[lang]}/>
            </Container>
        </AddPackagesSection>
    </Layout>
}


export default GfOnlineShop
