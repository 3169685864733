import styled from "styled-components"
import { SectionWrapper} from "../WebsitePage/styles";
import { colors } from "../../const/colors"

export const TargetSection = styled(SectionWrapper)`
  background-color: ${colors.lightBg};
  .content {
    display:flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap:wrap;
    max-width:900px;
    margin: 50px auto;
  }
  .main-content {
    display:flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap:wrap;
    margin:50px auto;
    width:100%;
  }
  p {
    text-align: center;
  }
  .img-block {
    width:100%;
    img {
      display: block;
      margin:auto;
      max-width:350px;
    }
    @media screen and (min-width: 768px){
      width:49%;
    }
  }
  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap:wrap;
    width:100%;
    margin:30px auto auto auto;
    padding:0 20px;
    list-style-type: none;
    @media screen and (min-width: 768px){
      margin-top:auto;
      width:49%;
    }
  }
  li {
    width:100%;
    display:flex;
    align-items: center;
    @media screen and (min-width: 400px) {
      justify-content: center;
      width:48%;
    }
    svg {
      margin-right:10px;
    }
    @media screen and (min-width: 768px) {
      justify-content: flex-start;
    }
  }
  .button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
  }
`

export const ImagineItSection = styled(SectionWrapper)`
  overflow: hidden;
  .content {
    max-width:900px;
    margin:50px auto;
    text-align: center;
  }
  .img-block {
    position:relative;
    left:47px;
    margin:80px auto 50px auto;
    max-width:600px;
    width:100%;
    @media screen and (min-width: 768px){
      width:80%;
    }
  }
  span {
    color:${colors.grayColor};
    font-weight: bold;
    display: inline-block;
    margin-bottom:30px;
  }
  img {
    display:block;
    position:absolute;
    &.left {
      display:none;
      transform: rotate(10deg);
      @media screen and (min-width: 1400px){
        display:block;
        max-width:200px;
        width:100%;
        left:2%;
        top:-70px;
      }
    }
    &.right {
      display:none;
      @media screen and (min-width: 1140px) {
        display:block;
        max-width:230px;
        width:100%;
        right:-50px;
        bottom:-55px;
      }
    }
  }
`